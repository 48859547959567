<template>
    <div>
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>院前沟通</el-breadcrumb-item>
            <el-breadcrumb-item>已沟通记录</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin-top: 20px">
            <div class="search" style="text-align: center; margin-top: 30px">
                <el-form :model="search" :inline="true">
                    <el-form-item label="就诊日期">
                        <el-date-picker style="width: 140px"
                                        v-model="search.date"
                                        type="date"
                                        align="right"
                                        unlink-panels
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="关键词">
                        <el-input v-model="search.keywords" style="width: 200px"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div style="margin-left:50px;line-height: 30px;">
                            <div>患者姓名：{{ props.row.patient}}</div>
                            <div>身份证号：{{ props.row.idnum}}</div>
                            <div>联系电话：{{ props.row.phone}}</div>
                            <div>病情简述：{{ props.row.desc}}</div>
                            <div>诊疗预案：{{ props.row.plan}}</div>
                            <div>预约时间：{{ props.row.CreatedAt}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="small" type="primary">沟通记录</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div style="text-align: center;margin: 20px">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                               :total="table.total" :page-size="table.pageSize"
                               @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                search: {},
                table: {
                    columns: [
                        {title: '院区', field: 'hospital.name', width: ''},
                        {title: '科室', field: 'department.name', width: ''},
                        {title: '就诊日期', field: 'date', width: ''},
                        {title: '就诊时间', field: 'time', width: ''},
                        {title: '患者姓名', field: 'patient', width: ''},
                        {title: '联系电话', field: 'phone', width: ''},
                        {title: '医生姓名', field: 'doctor.name', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                form: {
                    hospital_id: null,
                    department_id: null,
                    date: '',
                    keywords: '',
                },
                rules: {
                    doctor: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleUpdate(row) {
                this.form = row
                this.$router.push(`/main/record/form?id=${row.id}`)
            },
            async handleDel(row){
                const resp = await this.$http.delete(`/mingde/appoint/${row.id}`)
                console.log(resp)
                this.$message.success("删除成功")
                this.getData()
            },
            async getData() {
                this.search.plan_todo = 1
                const resp = await this.$http.get('/mingde/appoint/', {params: this.search})
                this.table.data = resp.data.data.data || []
                this.table.total = resp.data.data.total
            },
            handleSearch() {
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .search {
        padding-bottom: 40px;
        text-align: center;
    }
    .avatar {
        width: 200px;
    }

</style>
